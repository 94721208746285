<template>
  <tabs-layout
    :config="config"
    :refresh-tabs="tabsForRefresh"
    :first-tab-key="$route.query.open_tab_key"
    :redirect-view-name="'MachineOperation'"
  >
    <template
      #dynamic-content
    >
      <signature-dialog
        v-if="signatureDialogData.show"
        :show-dialog="signatureDialogData.show"
        :config="signatureDialogData.config"
        :fetch-signature="signatureDialogData.fetchSignature"
        :signature-id="signatureDialogData.signatureId"
        :signature-type="signatureDialogData.signatureType"
        :instance="signatureDialogData.instance"
        :tab-key="signatureDialogData.tabKey"
        @close="closeSignatureDialog"
        @refresh-tabs="refreshInstance"
      />

      <send-for-signature-dialog
        v-if="sendForSignatureDialogData.show"
        :show-dialog="sendForSignatureDialogData.show"
        :title="sendForSignatureDialogData.title"
        :document-id="sendForSignatureDialogData.documentId"
        :api-config="sendForSignatureDialogData.apiConfig"
        :signature-type="sendForSignatureDialogData.signatureType"
        :additional-object="sendForSignatureDialogData.additionalObject"
        :instance="sendForSignatureDialogData.instance"
        :tab-key="sendForSignatureDialogData.tabKey"
        @close="onCloseSendForSignatureDialog"
        @refresh-tabs="refreshInstance"
      />

      <change-status
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        :current-status-id="changeStatusDialogConfig.current_status"
        :callback="changeStatusDialogConfig.callback"
        :close-dialog-callback="closeChangeStatusDialog"
      />
    </template>
  </tabs-layout>
</template>

<script>
import SignatureDialog from '@/modules/fleet-module/components/SignatureDialog.vue'
import tabsLayout from '@/global/components/view-layouts/tabs-layout/views/TabsLayout.vue'
import { api } from '@/global/services/api'
import ChangeStatus from '@/modules/fleet-module/components/ChangeStatus.vue'
import { isObject } from 'lodash'
import { getDateDifference, currentDate, currentTime } from '@/global/services/helpers/dates'
import SendForSignatureDialog from '@/modules/fleet-module/components/SendForSignatureDialog.vue'
import ColumnsContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue'
import dayjs from 'dayjs'

const columnsContentComponent = ColumnsContent
export default {
  name: 'MachineOperationDetailsView',

  components: {
    SendForSignatureDialog,
    ChangeStatus,
    SignatureDialog,
    tabsLayout
  },

  data () {
    return {
      signatureDialogData: {
        show: false,
        config: {},
        fetchSignature: true,
        signatureId: null,
        signatureType: null,
        instance: null,
        tabKey: ''
      },
      sendForSignatureDialogData: {
        show: false,
        title: this.$t('fleet/documents/prl_cargo.send_for_signature_title'),
        apiConfig: {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/request-signature'
        },
        documentId: this.$route.params.id,
        additionalObject: {},
        signatureType: null,
        instance: null,
        tabKey: ''
      },
      signatureDialogConfig: {},
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        document_id: null,
        current_status: null,
        callback: null
      },
      tabsForRefresh: [],
      basicInfoPermissions: {},
      machineOperationDataPermissions: {}
    }
  },

  computed: {
    config () {
      return {
        tabs: [
          // Basic info tab
          {
            key: 'basic-info',
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/machine-operation/${this.$route.params.id}/basic-info`,
                method: 'get'
              },
              post: {
                module: 'fleet',
                route: `travel-documents/machine-operation/${this.$route.params.id}/basic-info`,
                method: 'post'
              }
            },
            hiddenFormKeys: [
              'vehicle_id'
            ],
            permissions: this.basicInfoPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.basicInfoPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
              this.$set(this.basicInfoPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
            },
            tabContent: [
              // Document data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/machine.document_data'),
                component: columnsContentComponent,
                showButtons: true,
                showBackButton: {
                  uiViewName: 'MachineOperation'
                },
                fullWidth: true,
                additional_buttons: [
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                    prependIcon: 'mdi-file-pdf-box',
                    click: () => this.onMachineViewPdf()
                  },
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.change_status'),
                    prependIcon: 'mdi-swap-horizontal',
                    visible: (event) => {
                      const { permissions } = event || {}

                      return permissions && permissions.canChangeStatus
                    },
                    click: (event) => this.onChangeStatus(event)
                  }
                ],
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        row: 1,
                        column: 1,
                        label: this.$t('fleet/documents/machine.machine_operation_document_number'),
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        row: 1,
                        column: 2,
                        label: this.$t('fleet/documents/prl_cargo.date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        row: 1,
                        column: 3,
                        label: this.$t('fleet/vehicle.status'),
                        options: {
                          custom_display_value: (value) => {
                            return value ? this.$t(value) : ''
                          }
                        },
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'created_at',
                        row: 1,
                        column: 4,
                        label: this.$t('fleet/documents/machine.document_creation_time'),
                        placeholder: 'HH:mm',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'company_name',
                        row: 2,
                        column: 1,
                        colSpan: 2,
                        label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'organizational_unit',
                        row: 2,
                        column: 3,
                        label: this.$t('fleet.organizational_unit'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'department',
                        row: 2,
                        column: 4,
                        label: this.$t('fleet.department'),
                        creatable: true,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Machine data card
              {
                subTitle: this.$t('fleet/documents/machine.machine_data'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onMachineRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_registration ? value.vehicle_registration : null
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'vehicle',
                            document_type: 'MACHINE_OPERATION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onMachineGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_garage_number ? value.vehicle_garage_number : null
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'MACHINE_OPERATION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Customer information card
              {
                subTitle: this.$t('fleet/documents/machine.customer_information'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'client_name',
                        label: this.$t('fleet/documents/machine.client_name'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'construction_site',
                        label: this.$t('fleet/documents/machine.construction_site_drive'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'section',
                        label: this.$t('fleet/documents/machine.section'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'expense_location',
                        label: this.$t('fleet/documents/prl_cargo.expense_location'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Machine operation data tab
          {
            key: 'machine-operation-data',
            title: this.$t('fleet/documents/machine.machine_operation_data'),
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/machine-operation/${this.$route.params.id}/operation-data`,
                method: 'get'
              },
              post: {
                module: 'fleet',
                route: `travel-documents/machine-operation/${this.$route.params.id}/operation-data`,
                method: 'post'
              }
            },
            permissions: this.machineOperationDataPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.machineOperationDataPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
              this.$set(this.machineOperationDataPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
            },
            additionalSetup: (event) => this.machineOperationAdditionalSetup(event),
            tabContent: [
              {
                title: this.$t('fleet/documents/machine.machine_operation_data'),
                component: columnsContentComponent,
                showButtons: true,
                showBackButton: {
                  uiViewName: 'MachineOperation'
                },
                fullWidth: true,
                additional_buttons: [
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                    prependIcon: 'mdi-file-pdf-box',
                    click: () => this.onMachineViewPdf()
                  },
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.change_status'),
                    prependIcon: 'mdi-swap-horizontal',
                    visible: (event) => {
                      const { permissions } = event || {}

                      return permissions && permissions.canChangeStatus
                    },
                    click: (event) => this.onChangeStatus(event)
                  }
                ],
                bordered: false,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 1,
                    fields: [
                      {
                        key: 'shift_no',
                        label: this.$t('fleet/documents/machine.shift'),
                        type: 'autocomplete',
                        autocomplete_options: {
                          fetchInitial: false,
                          allowSearch: false
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Machine operation card
              {
                subTitle: this.$t('fleet/documents/machine.document_data'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    subTitle: this.$t('fleet/documents/machine.machine_operation_subtitle'),
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'engineer_name',
                        label: this.$t('fleet/documents/machine.engineers_name_surname'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'started_at',
                        label: this.$t('fleet/documents/machine.machinist_worked_from'),
                        type: 'datetime-picker',
                        onInput: (value, data) => {
                          const { updateData, formData, updateValidationErrors } = data || {}

                          if (value && formData && formData.completed_at) {
                            const workDuration = getDateDifference(value, formData.completed_at, 'hour', 'DD.MM.YYYY. HH:mm')
                            const errors = {}

                            // If work duration is less than 0 that means that start date is greater than end date
                            // Show error message for this field and reset work duration field
                            if (workDuration < 0) {
                              errors.started_at = this.$t('fleet/documents/machine.start_date_less_than_end_date_message')
                              if (typeof updateData === 'function') {
                                updateData({ work_duration: '' })
                              }
                            }
                            // Otherwise, reset error message (if exists) for end date field and show work duration
                            else {
                              errors.completed_at = ''
                              if (typeof updateData === 'function') {
                                updateData({ work_duration: workDuration })
                              }
                            }

                            if (typeof updateValidationErrors === 'function') {
                              updateValidationErrors(errors)
                            }
                          }
                        },
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'completed_at',
                        label: this.$t('fleet/documents/machine.machinist_worked_to'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        onInput: (value, data) => {
                          const { updateData, formData, updateValidationErrors } = data || {}

                          if (value && formData && formData.started_at) {
                            const workDuration = getDateDifference(formData.started_at, value, 'hour', 'DD.MM.YYYY. HH:mm')
                            const errors = {}

                            // If work duration is less than 0 that means that end date is less than start date
                            // Show error message for this field and reset work duration field
                            if (workDuration < 0) {
                              errors.completed_at = this.$t('fleet/documents/machine.end_date_greater_than_start_date_message')
                              if (typeof updateData === 'function') {
                                updateData({ work_duration: '' })
                              }
                            }
                            // Otherwise, reset error message (if exists) for start date field and show work duration
                            else {
                              errors.started_at = ''
                              if (typeof updateData === 'function') {
                                updateData({ work_duration: workDuration })
                              }
                            }

                            if (typeof updateValidationErrors === 'function') {
                              updateValidationErrors(errors)
                            }
                          }
                        },
                        format: 'DD.MM.YYYY. HH:mm',
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'work_duration',
                        label: this.$t('fleet/documents/machine.work_duration'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'machine_output_m3',
                        label: this.$t('fleet/documents/machine.machine_output_m3'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'machine_output',
                        label: this.$t('fleet/documents/machine.machine_performance'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'unit_of_measure',
                        label: this.$t('fleet/documents/machine.unit_of_measure'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'effective_machine_work',
                        label: this.$t('fleet/documents/machine.effective_machine_work'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'odometer_start',
                        label: this.$t('fleet/documents/machine.odometer_start'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'odometer_end',
                        label: this.$t('fleet/documents/machine.odometer_end'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Downtime in hours card
              {
                subTitle: this.$t('fleet/documents/machine.downtime_in_hours'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'stand_by',
                        label: this.$t('fleet/documents/machine.waiting_for_work'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'machine_defects',
                        label: this.$t('fleet/documents/machine.machine_defects'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'electricity_maintenance',
                        label: this.$t('fleet/documents/machine.electrical_maintenance_energy'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'defect',
                        label: this.$t('fleet/documents/machine.lack'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'bad_weather',
                        label: this.$t('fleet/documents/machine.storm'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Consumption card
              {
                subTitle: this.$t('fleet/documents/machine.consumption'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'fuel_type_id',
                        label: this.$t('fleet/vehicle.fuel_type'),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'vehicles/fuel-types',
                          allowSearch: false
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'fuel_amount',
                        label: this.$t('fleet/documents/machine.fuel_amount'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'motor_oil_amount',
                        label: this.$t('fleet/documents/machine.engine_oil_amount'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'differential_oil_amount',
                        label: this.$t('fleet/documents/machine.differential_oil_amount'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'grease_amount',
                        label: this.$t('fleet/documents/machine.grease_amount'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Machinist's remark card
              {
                subTitle: this.$t('fleet/documents/machine.machinists_remark'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'remarked_at',
                        label: this.$t('fleet/documents/machine.enrollment_datetime'),
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'remarks',
                        label: this.$t('fleet/documents/machine.remark'),
                        onInput: (value, data) => {
                          const { updateData } = data || {}

                          if (typeof updateData === 'function') {
                            const remarkedAt = value
                              ? `${currentDate('DD.MM.YYYY.')} ${currentTime('HH:mm')}`
                              : ''

                            updateData({
                              remarked_at: remarkedAt
                            })
                          }
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Signatures card
              {
                subTitle: this.$t('fleet/documents/machine.signatures'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'engineer_signature_name',
                        row: 1,
                        column: 1,
                        label: this.$t('fleet/documents/machine.engineers_signature'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'engineer_signed_at',
                        row: 1,
                        column: 2,
                        label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'manager_signature_name',
                        row: 1,
                        column: 3,
                        label: this.$t('fleet/documents/machine.facility_manager_signature'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'manager_signed_at',
                        row: 1,
                        column: 4,
                        label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        type: 'content-with-buttons',
                        row: 2,
                        column: 1,
                        key: 'engineer_signature_image',
                        label: this.$t('fleet/documents/machine.engineers_signature'),
                        contentStack: 'horizontal',
                        contentType: 'image',
                        buttons: [
                          {
                            type: 'save',
                            label: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.engineer_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                            },
                            visible: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.engineer_can_sign
                            },
                            disabled: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.engineer_signature_image
                            },
                            prependIcon: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.engineer_signature_image ? 'mdi-check' : 'mdi-draw'
                            },
                            click: (event) => this.onSign(event, null, 'MACHINE_OPERATION_ENGINEER', true)
                          }
                        ],
                        contentStyle: {
                          height: '88px',
                          minWidth: '270px',
                          border: '1px solid #E5E7EB',
                          borderRadius: '16px',
                          marginRight: '10px'
                        },
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        type: 'content-with-buttons',
                        row: 2,
                        column: 3,
                        key: 'manager_signature_image',
                        label: this.$t('fleet/documents/machine.facility_manager_signature'),
                        buttons: [
                          {
                            type: 'save',
                            label: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                            },
                            visible: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_can_sign
                            },
                            disabled: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image
                            },
                            color: this.$route.query.sign_button_color ?? '',
                            prependIcon: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image ? 'mdi-check' : 'mdi-draw'
                            },
                            click: (event) => this.onSign(event, null, 'MACHINE_OPERATION_MANAGER', true)
                          },
                          {
                            type: 'save',
                            label: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                            },
                            visible: () => {
                              const { canEdit } = this.machineOperationDataPermissions || {}
                              return canEdit
                            },
                            disabled: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image
                            },
                            prependIcon: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.manager_signature_image ? 'mdi-check' : 'mdi-send'
                            },
                            prependIconStyle: {
                              color: '#FFFFFF !important',
                              marginRight: '4px',
                              fontSize: '19px'
                            },
                            click: (event) => this.onManagerSendForSignature(event)
                          }
                        ],
                        contentStack: 'horizontal',
                        contentType: 'image',
                        contentStyle: {
                          height: '88px',
                          minWidth: '270px',
                          border: '1px solid #E5E7EB',
                          borderRadius: '16px',
                          marginRight: '10px'
                        },
                        creatable: false,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.machine_operation')
            },
            {
              text: this.$route.query.bread_crumb_value ? this.$t('fleet/documents/machine.machine_operation_document_number') + ' - ' + this.$route.query.bread_crumb_value : this.$t('fleet/documents/prl_cargo.document_edit'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    onCloseSendForSignatureDialog () {
      this.$set(this.sendForSignatureDialogData, 'show', false)
    },

    onSign (event, data, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'tabKey', 'machine-operation-data')
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onManagerSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'tabKey', 'machine-operation-data')
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'MACHINE_OPERATION_MANAGER')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    machineOperationAdditionalSetup (event) {
      const { initialAutocompletes } = event || {}
      this.$set(initialAutocompletes, 'shift_no', {
        data: [
          {
            id: 1,
            name: '1'
          },
          {
            id: 2,
            name: '2'
          },
          {
            id: 3,
            name: '3'
          }
        ],
        hasMore: false
      })
    },

    async refreshInstance (tabKey) {
      const tabsForRefreshLocal = []

      if (tabKey && typeof tabKey === 'string') {
        tabsForRefreshLocal.push(tabKey)
      }

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async onMachineViewPdf () {
      const documentId = this.$route.params.id

      if (!documentId) {
        console.error('Document id is mandatory')
        return
      }

      const url = new URL(`${window.location.origin}/api/fleet/travel-documents/machine-operation/pdf-report`)
      let objectURL = null

      try {
        const response = await fetch(url.toString(), {
          method: 'POST',
          body: JSON.stringify({
            travel_document_id: documentId
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (!response.ok) {
          console.error(`Failed to show PDF: HTTP ${response.status} - ${response.statusText}`)
          return
        }

        const arrayBuffer = await response.arrayBuffer()

        // Create a Blob from the response data
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: 'application/pdf'
        })

        // Generate object URL for downloading
        objectURL = URL.createObjectURL(blob)
        const filename = `${this.$t('fleet.machine_operation')}_${dayjs().format('YYYYMMDDHHmmss')}.pdf`

        // Trigger download
        const link = document.createElement('a')
        link.href = objectURL
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      catch (exception) {
        console.error('Error showing pdf:', exception.message || exception)
      }
      finally {
        // Revoke object URL to release memory
        if (objectURL) {
          URL.revokeObjectURL(objectURL)
        }
      }
    },

    async closeSignatureDialog () {
      this.$set(this.signatureDialogData, 'show', false)
    },

    async onChangeStatus (event) {
      let currentFetchedStatusId = null

      if (event?.formData?.status) {
        currentFetchedStatusId = event.formData.status.id
      }

      if (event?.data?.status) {
        currentFetchedStatusId = event.data.status
      }

      this.$set(this.changeStatusDialogConfig, 'current_status', currentFetchedStatusId)

      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
        this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback())
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
            this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback())
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    changeStatusCallback () {
      const tabsForRefreshLocal = []
      tabsForRefreshLocal.push('basic-info')
      tabsForRefreshLocal.push('machine-operation-data')

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async onMachineRegistrationSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_garage_number: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: selectedItem.organizational_unit || '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'MACHINE_OPERATION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_garage_number: selectedItem.vehicle_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onMachineGarageNumberSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_registration: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: selectedItem.organizational_unit || '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'MACHINE_OPERATION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_registration: selectedItem.vehicle_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    }
  }
}
</script>
