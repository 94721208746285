<template>
  <tabs-layout
    :config="config"
    :redirect-view-name="'PrlCargo'"
    :first-tab-key="$route.query.open_tab_key"
    :refresh-tabs="tabsForRefresh"
  >
    <template
      #dynamic-content
    >
      <signature-dialog
        v-if="signatureDialogData.show"
        :show-dialog="signatureDialogData.show"
        :config="signatureDialogData.config"
        :fetch-signature="signatureDialogData.fetchSignature"
        :signature-id="signatureDialogData.signatureId"
        :signature-type="signatureDialogData.signatureType"
        :instance="signatureDialogData.instance"
        :additional-object="signatureDialogData.additionalObject"
        :tab-key="signatureDialogData.tabKey"
        :success-callback="signatureDialogData.successCallback"
        @close="closeSignatureDialog"
        @refresh-tabs="refreshTabs"
        @refresh-instance="refreshInstance"
      />

      <third-party-signature
        v-if="thirdPartySignatureDialogConfig.show"
        :show-dialog="thirdPartySignatureDialogConfig.show"
        :title="thirdPartySignatureDialogConfig.title"
        :api-config="thirdPartySignatureDialogConfig.apiConfig"
        :signature-type="thirdPartySignatureDialogConfig.signatureType"
        :document-id="$route.params.id"
        :additional-object="thirdPartySignatureDialogConfig.additionalObject"
        :instance="thirdPartySignatureDialogConfig.instance"
        @close="closeThirdPartySignatureDialog"
        @refresh-instance="refreshInstance"
      />

      <change-status-dialog
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        :current-status-id="changeStatusDialogConfig.currentStatusId"
        :callback="changeStatusDialogConfig.callback"
        :close-dialog-callback="closeChangeStatusDialog"
      />

      <send-for-signature-dialog
        v-if="sendForSignatureDialogData.show"
        :show-dialog="sendForSignatureDialogData.show"
        :title="sendForSignatureDialogData.title"
        :document-id="sendForSignatureDialogData.documentId"
        :api-config="sendForSignatureDialogData.apiConfig"
        :signature-type="sendForSignatureDialogData.signatureType"
        :additional-object="sendForSignatureDialogData.additionalObject"
        :instance="sendForSignatureDialogData.instance"
        @close="onCloseSendForSignatureDialog"
        @refresh-instance="refreshInstance"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </tabs-layout>
</template>

<script>
import SignatureDialog from '../../../fleet-module/components/SignatureDialog.vue'
import tabsLayout from '@/global/components/view-layouts/tabs-layout/views/TabsLayout.vue'
import ThirdPartySignature from '../../../fleet-module/components/ThirdPartySignature.vue'
import ChangeStatusDialog from '../../../fleet-module/components/ChangeStatus.vue'
import SendForSignatureDialog from '@/modules/fleet-module/components/SendForSignatureDialog.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'
import { isEmpty } from 'lodash'
import ColumnsContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue'
import TableGridContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/TableGridContent.vue'
import vehicleMovementSignatureTable from '../../../fleet-module/components/VehicleMovementSignatureTable.vue'
import basicInfoConfig from '../js/edit/basicInfoConfig'
import vehicleMovementConfig from '../js/edit/vehicleMovementConfig'
import garageConfig from '../js/edit/garageConfig'
import fuelConfig from '../js/edit/fuelConfig'
import tiresConfig from '../js/edit/tiresConfig'
import plannedConfig from '../js/edit/plannedConfig'
import common from '../js/common'

export default {
  name: 'PrlCargoDetailsView',

  mixins: [
    basicInfoConfig,
    vehicleMovementConfig,
    garageConfig,
    fuelConfig,
    tiresConfig,
    plannedConfig,
    common
  ],

  components: {
    InformationDialog,
    SendForSignatureDialog,
    ChangeStatusDialog,
    ThirdPartySignature,
    SignatureDialog,
    tabsLayout
  },

  data () {
    return {
      signatureDialogData: {
        show: false,
        config: {},
        fetchSignature: true,
        signatureId: null,
        signatureType: null,
        instance: null,
        tabKey: null,
        additionalObject: null,
        successCallback: null
      },
      thirdPartySignatureDialogConfig: {
        show: false,
        title: '',
        apiConfig: {},
        additionalObject: {},
        signatureType: null,
        instance: null
      },
      defaultThirdPartySignatureDialogConfig: {
        show: false,
        title: '',
        apiConfig: {},
        additionalObject: {},
        signatureType: null,
        instance: null
      },
      sendForSignatureDialogData: {
        show: false,
        title: this.$t('fleet/documents/prl_cargo.send_for_signature_title'),
        apiConfig: {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/request-signature'
        },
        documentId: this.$route.params.id,
        additionalObject: {},
        signatureType: null,
        instance: null
      },
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      },
      vehicleRegistrationAutocompleteField: null,
      vehicleGarageNumberAutocompleteField: null,
      tabsForRefresh: [],
      currentStatusId: null,
      tableGridContentComponent: TableGridContent,
      vehicleMovementSignatureTable: vehicleMovementSignatureTable,
      columnsContentComponent: ColumnsContent
    }
  },

  computed: {
    config () {
      return {
        tabs: [
          // Basic info tab
          this.basicInfo,
          // Vehicle movement tab
          this.vehicleMovement,
          // Garage tab
          this.garage,
          // Fuel and lubricant tab
          this.fuel,
          // Mileage tires tab
          this.tires,
          // Planned completion tab
          this.planned
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo')
            },
            {
              text: this.$route.query.foreign_code ?? this.$t('fleet/documents/prl_cargo.document_edit'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    },

    async onCloseSendForSignatureDialog () {
      this.$set(this.sendForSignatureDialogData, 'show', false)
    },

    async refreshInstance (instance) {
      const { fetchData, fetchTabData, detailsDataIndex, detailsConfig, showTableDetailsPage, showTableDetailsPageFromOutside } = instance || {}

      if (this.$route.query.item_id && showTableDetailsPageFromOutside && fetchData) {
        await fetchData()
        showTableDetailsPageFromOutside(this.$route.query.item_id, this.fuelLubricantDetails)
      }
      else if (fetchData && detailsDataIndex !== undefined && !isEmpty(detailsConfig) && showTableDetailsPage) {
        await fetchData()
        showTableDetailsPage(detailsDataIndex, detailsConfig)
      }
      else if (fetchTabData) {
        await fetchTabData()
      }
      else if (fetchData) {
        await fetchData()
      }
    },

    refreshTabs (tabKey) {
      const tabsForRefreshLocal = []

      if (tabKey && typeof tabKey === 'string') {
        tabsForRefreshLocal.push(tabKey)
      }

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async closeSignatureDialog () {
      this.$set(this.signatureDialogData, 'show', false)
    },

    async closeThirdPartySignatureDialog () {
      this.thirdPartySignatureDialogConfig = this.defaultThirdPartySignatureDialogConfig
      this.$set(this.thirdPartySignatureDialogConfig, 'show', false)
    }
  }
}
</script>
